'use strict';

Gri.module({
  name: 'colors-beta',
  ieVersion: null,
  $el: $('.colors-beta'),
  container: '.colors-beta',
  fn: function () {
    this.$el.find(".color").click(function () {
      var imgSrc = $(this).find(".box").data("img");
      $(".change-img").attr("src",imgSrc);
    })
  }
});
